import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Response } from 'app/shared/models/response/response.model';
import { ApiService } from '../../api.service';
import { AccountGroup } from 'app/shared/models/accounts/accountgroup/accountgroup.model';
import { DatePipe } from '@angular/common';


@Injectable()
export class AccountGroupService {
    static GET_PATH = `${environment.apiPath}` + 'getgroupaccountsbysearchcriteria';
    static SAVE_PATH = `${environment.apiPath}` + 'savegroupaccount';
    static UPDATE_PATH = `${environment.apiPath}` + 'updategroupaccount';
    static DELETE_PATH = `${environment.apiPath}` + 'deletegroupaccount';
    constructor(private apiService: ApiService, private datepipe: DatePipe) { }
    saveAccountGroup(accountGroup: AccountGroup): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(AccountGroupService.SAVE_PATH, accountGroup).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    updateAccountGroup(accountGroup: AccountGroup): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(AccountGroupService.UPDATE_PATH, accountGroup).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: accountGroup.AccGroupName + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = error.error.Message;
                }
                observer.next(res);
            });
        });
        return observable;
    }
    deleteAccountGroup(groupId: number, isActiveStatus: boolean, groupName: string): Observable<any> {
        let apiUrl = AccountGroupService.DELETE_PATH;
        apiUrl = apiUrl + '/' + groupId + '/' + (!isActiveStatus);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: groupName + (!isActiveStatus
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = environment.errorMessages[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    searchAccountGroups(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Name: searchObj.groupName,
                FromDate: new Date(searchObj.fromdate.year, searchObj.fromdate.month - 1,
                    searchObj.fromdate.day).toDateString(),
                ToDate: new Date(searchObj.todate.year, searchObj.todate.month - 1,
                    searchObj.todate.day).toDateString(),
                IsActive: searchObj.isActive
            };
            this.apiService.postData(AccountGroupService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAccountGroupList(result.AccountGroups), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateAccountGroupList(groups: any): any {
        const groupList = [];
        for (let index = 0; index < groups.length; index++) {
            const group = {
                sNo : index + 1,
                id: groups[index].AccGroupId,
                groupName : groups[index].AccGroupName,
                description : groups[index].Description,
                isActive : groups[index].IsActive  ? 'Yes' : 'No',
                createdOn: this.datepipe.transform(groups[index].CreatedOn, 'yyyy-MM-dd'),
            };
            groupList.push(group);
        }
        return groupList;
    }
}
