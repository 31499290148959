import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Response } from 'app/shared/models/response/response.model';
import { ProductHSNCode } from 'app/shared/models/producthsncode/producthsncode.model';


@Injectable()
export class ProductHSNCodeService {
    static GET_PATH = `${environment.apiPath}` + 'getproducthsncodebysearchcriteria';
    static SAVE_PATH = `${environment.apiPath}` + 'addhsncode';
    static UPDATE_PATH = `${environment.apiPath}` + 'updatehsncode';
    static DELETE_PATH = `${environment.apiPath}` + 'deletehsncode';
    constructor(private apiService: ApiService) { }

    searchProductHSNCode(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                HSNCode: searchObj.hsnCode, IsActive: searchObj.isActive
            };
            this.apiService.postData(ProductHSNCodeService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateProductHSNCodeList(result.ProductHSNCodes), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.
                    errorMessages[error.error.Message || error.error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateProductHSNCodeList(code: any): any {
        const hsnCodeist = [];
        for (let index = 0; index < code.length; index++) {
            const hSNCode = {
                sNo : index + 1,
                id: code[index].Id,
                hsnCode : code[index].HSNCode,
                gstRate : code[index].GSTRate,
                isActive : code[index].IsActive ? 'Yes' : 'No'
            };
            hsnCodeist.push(hSNCode);
        }
        return hsnCodeist;
    }

    saveProductHSNCode(hsn: ProductHSNCode): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(ProductHSNCodeService.SAVE_PATH, hsn).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    updateProductHSNCode(hsn: ProductHSNCode): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(ProductHSNCodeService.UPDATE_PATH, hsn).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: hsn.HSNCode + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = error.error.Message;
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteHSNCode(hsnId: number, isActiveStatus: boolean, hSNCode: string): Observable<any> {
        let apiUrl = ProductHSNCodeService.DELETE_PATH;
        apiUrl = apiUrl + '/' + hsnId + '/' + (!isActiveStatus);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: hSNCode + (!isActiveStatus
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error =  error.error.Message;
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
